export const supportsMonth = {
    6: "prideMonth",
    9: "setembroAmarelo",
    10: "outubroRosa",
    11: "blm",
    12: "christmas",
}


export const supportsAllTime = [
    "prideMonth", "blm"
]
