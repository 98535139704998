const events = [
    {
        title: "Café com Elas",
        description: '"Café com Elas" na programação da Jornada Acadêmica Computação@UFCG.',
        day: "5",
        month: "11",
        year: "2020",
        time: "20h",
        local: "Discord CCC",
        pageURL: "",
        eventURL: "",
    },
    {
        title: "Mês dElas",
        description: 'Adicione todos os eventos da programação em sua agenda! Abertura 04/03 às 20h no Café com Elas!',
        day: "4",
        month: "3",
        year: "2020",
        time: "20h",
        local: "Discord CCC",
        pageURL: "",
        eventURL: "https://calendar.google.com/calendar/u/2?cid=Y19raW5jMGFyMmtuOTljbmJsNGRtdmU1NWVrMEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t",
    },
    {
        title: "Café com Elas",
        description: '"Café com Elas" na programação do Mês dElas.',
        day: "4",
        month: "3",
        year: "2021",
        time: "20h",
        local: "Discord CCC",
        pageURL: "",
        eventURL: "",
    },
    {
        title: "Live com Planet Cell",
        description: 'Live em parceria com Planet Cell',
        day: "5",
        month: "3",
        year: "2021",
        time: "19h",
        local: "",
        pageURL: "https://www.instagram.com/planetcelloficial/",
        eventURL: "",
    },
    {
        title: "Workshop Processamento de Imagens",
        description: 'Vamos aprender a lidar com imagens utilizando Python, OpenCV e NumPy!',
        day: "6",
        month: "3",
        year: "2021",
        time: "14h",
        local: "Google Meet",
        pageURL: "",
        eventURL: "",
    },
    {
        title: "Palestra no IPEN - 5º ano",
        description: 'Apresentando o Elas@Computação com a palestra "Só podia ser mulher!"',
        day: "9",
        month: "3",
        year: "2021",
        time: "9h50min",
        local: "",
        pageURL: "https://linktr.ee/ipencb",
        eventURL: "",
    },
    {
        title: "Palestra no IPEN - 4º ano",
        description: 'Apresentando o Elas@Computação com a palestra "Só podia ser mulher!"',
        day: "9",
        month: "3",
        year: "2021",
        time: "14h40min",
        local: "",
        pageURL: "https://linktr.ee/ipencb",
        eventURL: "",
    },
    {
        title: "Palestra no IPEN - 4º ano",
        description: 'Apresentando o Elas@Computação com a palestra "Só podia ser mulher!"',
        day: "10",
        month: "3",
        year: "2021",
        time: "8h20min",
        local: "",
        pageURL: "https://linktr.ee/ipencb",
        eventURL: "",
    },
    {
        title: "Palestra no IPEN - 5º ano",
        description: 'Apresentando o Elas@Computação com a palestra "Só podia ser mulher!"',
        day: "10",
        month: "3",
        year: "2021",
        time: "15h",
        local: "",
        pageURL: "https://linktr.ee/ipencb",
        eventURL: "",
    },
    {
        title: "Por que aprender Python?",
        description: 'Palestra sobre as aplicações e uso da linguagem Python atualmente! (ministrante: Mirlla Marques)',
        day: "13",
        month: "3",
        year: "2021",
        time: "15h",
        local: "Google Meet",
        pageURL: "https://www.instagram.com/mirlla_marques/",
        eventURL: "",
    },
    {
        title: "Introdução à Cloud",
        description: 'Vamos aprender conceitos sobre computação na nuvem?! (ministrante: Marta Laís)',
        day: "13",
        month: "3",
        year: "2021",
        time: "15h",
        local: "Google Meet",
        pageURL: "https://www.instagram.com/martelais/",
        eventURL: "",
    },
    {
        title: "Mini-curso Django",
        description: 'Vamos aprender sobre esse framework de desenvolvimento web com Python!',
        day: "20",
        month: "3",
        year: "2021",
        time: "15h",
        local: "",
        pageURL: "",
        eventURL: "",
    },
    {
        title: "Palestra de encerramento",
        description: 'Palestra de encerramento tratando sobre mulheres em projetos!',
        day: "27",
        month: "3",
        year: "2021",
        time: "15h",
        local: "",
        pageURL: "",
        eventURL: "",
    },
    {
        title: "Mês dElas - Abertura",
        description: 'Conversa com as comunidades dElas',
        day: "8",
        month: "3",
        year: "2022",
        time: "19h",
        local: "Youtube",
        pageURL: "",
        eventURL: "https://youtu.be/Q-EFEC5ysZo",
    },
    {
        title: "Mês dElas - Café com Elas",
        description: 'Síndrome de impostora',
        day: "10",
        month: "3",
        year: "2022",
        time: "19h",
        local: "Youtube",
        pageURL: "",
        eventURL: "https://youtu.be/4YXw6JXJWpE",
    },
    {
        title: "Mês dElas - Mentoria",
        description: 'Currículo e GitHub',
        day: "17",
        month: "3",
        year: "2022",
        time: "19h",
        local: "Youtube",
        pageURL: "",
        eventURL: "https://youtu.be/7LqlBbJjFLo",
    },
    {
        title: "Mês dElas - Início da semana de Minicursos",
        description: 'Disponíveis no formato assíncrono.',
        day: "21",
        month: "3",
        year: "2022",
        time: "19h",
        local: "Youtube",
        pageURL: "",
        eventURL: "https://youtube.com/channel/UCeEY-4r-CFB0xYfWWkjRW3g",
    },
    {
        title: "Mês dElas - Fim da semana de Minicursos",
        description: 'Disponíveis no formato assíncrono.',
        day: "31",
        month: "3",
        year: "2022",
        time: "19h",
        local: "Youtube",
        pageURL: "",
        eventURL: "https://youtube.com/channel/UCeEY-4r-CFB0xYfWWkjRW3g",
    },
]

export default events;