const icons = {
    rainbowRibbon: "https://www.flaticon.com/svg/static/icons/svg/2292/2292506.svg",
    yellowRibbon: "https://www.flaticon.com/svg/static/icons/svg/240/240485.svg",
    blueRibbon: "https://www.flaticon.com/svg/static/icons/svg/240/240383.svg",
    pinkRibbon: "https://cdn-icons-png.flaticon.com/512/3554/3554104.png",
    greenRibbon: "https://www.flaticon.com/svg/static/icons/svg/240/240434.svg",
    brasilFlag: "https://cdn-icons-png.flaticon.com/512/330/330430.png",
    usaFlag: "https://cdn-icons-png.flaticon.com/512/330/330459.png",
    spainFlag: "https://cdn-icons-png.flaticon.com/512/330/330557.png",
    lgbtFlag: "https://cdn-icons-png.flaticon.com/512/6421/6421208.png",
    girlPower: "https://cdn-icons.flaticon.com/png/512/4166/premium/4166895.png?token=exp=1646450838~hmac=ae5940df5eafb154cc9626bdb3b43577",
    blackLivesMatter: "https://cdn-icons.flaticon.com/png/512/3146/premium/3146926.png?token=exp=1646450770~hmac=e8b4b5076b45fa808f23e753e3a370d1",
    santaClaus: "https://cdn-icons-png.flaticon.com/512/621/621873.png"
}

export default icons;